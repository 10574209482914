"use client";

import useUiActions from "@/hooks/ui/useUiActions";
import type { Params } from "@/lib/routes/types";
import type { UiLanguage } from "@/redux/slices/ui/types";
import { useParams } from "next/navigation";
import { useCallback, useEffect, useMemo } from "react";
import { getLanguageJson } from "../i18n";

const useCustomTranslationClient = () => {
    const { lang: locale } = useParams<Params>();
    const { language, loadLanguage } = useUiActions();

    const hasLanguage = useMemo(() => !!language["error.title"], [language]);

    const initializeLanguage = useCallback(async () => {
        const languageJson = await getLanguageJson(locale);

        if (!hasLanguage) {
            loadLanguage(languageJson);

            return;
        }

        const isEqualLanguage = languageJson["global.soon"] === language["global.soon"];
        if (!isEqualLanguage) loadLanguage(languageJson);
    }, [hasLanguage, language, loadLanguage, locale]);

    useEffect(() => {
        initializeLanguage();
    }, [initializeLanguage, locale]);

    const translate = (key: keyof UiLanguage) => language[key];

    return {
        hasLanguage,
        translate
    };
};

export default useCustomTranslationClient;
